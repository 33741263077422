/**
 * ReactJS, SCSS
 * Error Page
 * Adam. G
 */

import {React, useEffect} from 'react';
import './index.scss';

/**
 * Custom Components
 */
 import CustomNav from '../../Components/Navbar/nav';
 import Footer from '../../Components/Footer/footer';

// Bootstrap
import { Container, Row, Col } from 'react-bootstrap';

function App() {

    useEffect(() => {
        document.title = '404 || Knaresborough Tyres';
    }, []);

    return (
        <>
            <CustomNav
                activePage={"home"}
            />
                <Container fluid className="errorContentContainer">
                    <Row>
                        <Col>
                            <div className="errorPage">
                                <h1>404</h1>
                                <h3>Doesn't look like this is the right place :(</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            <Footer />
        </>
    );
}

export default App;
