/**
 * ReactJS, SCSS
 * Tyres Page
 * Adam. G
 */

import {React, useState, useEffect} from 'react';
import './index.scss';

/**
 * Custom Components
 */
import CustomNav from '../../Components/Navbar/nav';
import Footer from '../../Components/Footer/footer';
//Lightbox
import Lightbox from '../../Components/Lightbox/lightbox';
// Content Card
import ContentCard from '../../Components/Card/card';
// Partners slider
import PartnersSlider from '../../Components/SalesPartners/partners';

/**
 * Imports
 */
// Bootstrap
import { Container, Row, Col } from 'react-bootstrap';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';

/**
* Images
*/
import Hancook from './../../Img/company-carousel/hancook.png';
import Davanti from './../../Img/company-carousel/davanti.png';
import Pirelli from './../../Img/company-carousel/pirelli.png';
import Yokohama from './../../Img/company-carousel/yokohama.png';

function App() {

    useEffect(() => {
        document.title = 'Car, 4x4, SUV, Van &amp; Motorcycle Tyres - Competitively Priced || Knaresborough Tyres';
    }, []);

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

    return (
        <>
            <CustomNav
                activePage={"home"}
                openFindUsLightbox={() => setLightboxIsOpen(true)}
            />
                <Container className="contentContainer">
                    <Row>
                        <Col lg="3" md="3" sm="12"  className="d-none d-lg-block d-md-block">
                            <Row>
                                <div className="sideImages">
                                    <img alt="Hancook Logo" src={Hancook} width="170px" />
                                </div>
                            </Row>
                            <Row>
                                <div className="sideImages">
                                    <img alt="Davanti Logo" src={Davanti} width="170px" />
                                </div>
                            </Row>
                            <Row>
                                <div className="sideImages">
                                    <img alt="Yokohama Logo" src={Yokohama} width="170px" />
                                </div>
                            </Row>
                            <Row>
                                <div className="sideImages">
                                    <img alt="Pirelli Logo" src={Pirelli} width="170px" />
                                </div>
                            </Row> 
                        </Col>
                        <Col>
                            <div className="tyresContent__partOne">
                                As a local business, we can offer significant discounts making our tyres the most excellent value for money in the Yorkshire area. For customers on a budget we offer a range of affordable tyres that still provide peace of mind and value for money backed up by excellent service from our trained technicians.
                                <br/>
                                <br/>
                                No appointment is needed for this service just turn up when it suits you, although some tyres may need to be ordered, you can call us ahead to check if we have your tyres in stock, if not, same day availability! We carry over 2000 tyres in stock at any one time, with same day availability on almost all tyres if we don't have it in stock.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="tyresContent__partTwo">
                                <i>We are always available for tyre pressure checks (no charge) & tyre health checks.</i>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="enviromental">
                                <FontAwesomeIcon icon={faLeaf} className="fa-flip-horizontal" /> <i>All waste products are disposed of in accordance with environmental guidelines.</i>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4 className="contentTitle">What Affects Tyre Wear?</h4> 
                            <div className="tyresContent__partThree">
                               <p>There are many different factors that affect how yours tyres wear down. Some of these factors can be mitigated by your driving style and some can't. Here's a quick run-down of what effects tyre wear:</p>
                               <br/>
                               <ul>
                                   <li>Aggressive driving - If you are cornering fast and braking hard your tyres will not thank you for it!</li>
                                   <li>Weight - Excess weight will cause your tyres to wear quicker, so clear out that boot of all unnecessary clutter!</li>
                                   <li>Tyre pressure - If your tyres are over inflated or under inflated they will wear much more quickly. Make sure you check tyre pressure often and use the correct PSI (pounds per square inch)</li>
                               </ul>
                               <p>Performance and safely are greatly compromised when using worn tyres. If you are unsure if your tyres need replacing drop by and we can give you some friendly impartial advice.</p>
                               <br/>
                               <p>For peace of mind stop by any time and we will check your tyre pressure and thread depth. We will give you free, friendly and impartial advice</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" md="4" sm="12">
                            <ContentCard
                                header="Car, Van &amp; SUV"
                                text="Facilitating everything and every vehicle from a simple battery change to complete MOTs, exhaust replacements &amp; more. Check out Ripon for our commercial &amp; Industrial."
                            >

                            </ContentCard>
                        </Col>
                        <Col lg="4" md="4" sm="12">
                            <ContentCard
                                header="Wheel Alignment"
                                text="You're safety is important to us, we have in house state of the art laser alignment equipment to give you complete confidence and ressurance in your vehicles handling."
                            >
                                
                            </ContentCard>
                        </Col>
                        <Col lg="4" md="4" sm="12">
                            <ContentCard
                                header="Same Day Availability"
                                text="If we don't have the right size tyre in stock we can get same day delivery to both Knaresborough &amp; Ripon for same day fitting (Subject to time of day &amp; external stock)"
                            >
                                
                            </ContentCard>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col>
                            <img src={Brands} className="brandsImage" alt="Tyre Brands We Supply" />
                        </Col>
                    </Row>*/}
                    <Row>
                        <Col>
                            <PartnersSlider />
                        </Col>
                    </Row>
                </Container>
            <Footer />
            <Lightbox
                showState={lightboxIsOpen}
                closeLightbox={() => setLightboxIsOpen(false)}
            >
                <div style={{ height: '100%', width: '100%' }}>
                    <iframe
                        title="Address"
                        width="100%"
                        height="100%"
                        style={{ 'border': 0 }}
                        loading="lazy"
                        allowfullscreen
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD4AS4Rve3NyVk-YbQn8AmrKufOBP04RSw
                            &q=Knaresborough+Tyre+Services+Ltd">
                    </iframe>
                </div>
            </Lightbox>
        </>
    );
}

export default App;
