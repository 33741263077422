/**
 * This page is the merge for all pages, the top level index purely acts
 * as a means to display the merge (this file) onto the root div.
 */
import React from 'react';

// Cookie consent
import CookieConsent from "react-cookie-consent";

// Pages
import IndexPage from './Index/index';
import Ripon from './Ripon/index';
import Tyres from './Tyres/index';
import Servicing from './Servicing/index';
import Batteries from './Batteries/index';
import ContentManagement from './Protected/ContentManagement/cms';
import Error from './Error/index';

// React Router
import {
  Router,
  Switch,
  Route
} from "react-router-dom";

import history from './history';

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
	<React.StrictMode>
        <Router history={history}>
			<Switch>
				{/**
					** This can be used if ever props need to be passed, though in order
					** to use the history, do the following in each file to allow history:
					**
					** import { withRouter } from 'react-router-dom';
					** export default withRouter(app);
					**
					** https://stackoverflow.com/questions/42701129/how-to-push-to-history-in-react-router-v4

					<Route exact path="/" render={(props) => <IndexPage {...props} />}/>
				 */}
				<Route exact path="/" component={IndexPage}/>
				<Route exact path="/ripon" component={Ripon}/>
        		<Route exact path="/tyres" component={Tyres}/>
        		<Route exact path="/servicing" component={Servicing}/>
				<Route exact path="/batteries" component={Batteries}/>
				<Route exact path="/contentmanagement" component={ContentManagement}/>
        		<Route component={Error}/>
			</Switch>
        </Router>
        <ToastContainer 
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={true}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
        />
        <CookieConsent
			location="bottom"
			buttonText="Okay, no problem!"
			cookieName="ktsConsentBanner"
			style={{ background: "#2B373B" }}
			buttonStyle={{ backgroundColor: '#eb5D0b', color: "#fff", fontSize: "13px" }}
			expires={150}
        >
          	Sorry to bug you, just so you know, we use cookies for Google maps and to give you the best experience while browsing, these are required for the website to function and don't track you.
        </CookieConsent>
	</React.StrictMode>
  )
}

export default App;
