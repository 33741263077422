/**
 * ReactJS, SCSS
 * Batteries Page
 * Adam. G
 */

import {React, useState, useEffect} from 'react';
import './index.scss';

/**
 * Custom Components
 */
import CustomNav from '../../Components/Navbar/nav';
import Footer from '../../Components/Footer/footer';
//Lightbox
import Lightbox from '../../Components/Lightbox/lightbox';

/**
 * Imports
 */
// Bootstrap
import { Container, Row, Col } from 'react-bootstrap';
// Content Card
import ContentCard from '../../Components/Card/card';
// Partners slider
import PartnersSlider from '../../Components/SalesPartners/partners';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';

function App() {

    useEffect(() => {
        document.title = 'Batteries & Exhausts - Great Prices On Full Car Exhaust Systems || Knaresborough Tyres';
    }, []);

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

    return (
        <>
            <CustomNav
                activePage={"home"}
                openFindUsLightbox={() => setLightboxIsOpen(true)}
            />
                <Container className="contentContainer">
                    <Row>
                        <Col lg="6" md="12"> 
                            <div className="batteriesContent__partOne">
                                With a large range of Exhausts available to our customers, we offer a same day fitting service on most makes and models of Cars, Vans and 4x4s. We stock exhausts by TIMAX and ARVIN plus many others. For which we offer a 2 year guarantee on Full or Part Systems. Our specially trained technicians will fit and help maintain your new exhaust system as well as sourcing Catalytic Converters at an affordable price.
                            </div>
                        </Col>
                        <Col lg="6" md="12">
                            <div className="batteriesContent__partTwo">
                                KTS is an official YUASA battery stockist carrying over 100 in stock at any time.. We cover the most popular makes of Car, Van and 4x4’s. All our YUASA batteries carry a full 3 year warranty with a fitting service available. Our prices are so good that we don’t carry budget ranges. With more than 45 years of experience we know that battery quality is essential and our discounted prices mean you can get a premium battery at an affordable price.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="enviromental">
                                <FontAwesomeIcon icon={faLeaf} className="fa-flip-horizontal" /> <i>All waste products are disposed of in accordance with environmental guidelines.</i>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4 className="contentTitle">How To Charge A Car Battery</h4> 
                            <div className="tyresContent__partThree">
                               <p>Changing the battery in your TV remote control is an easy 2 second job, but more care and attention is needed when changing a car battery.</p>
                               <br/>
                               <ul>
                                   <li>
                                        <b>Battery acid is very dangerous</b> It can burn clothing and skin. If you think your battery has been damaged it may be leaking so do not attempt to remove it yourself, ask a professional for help. 
                                   </li>
                                   <li>
                                        There is also the danger of <b>electric</b> shocks when changing a battery, if you have any doubts about how to avoid a shock then get someone who knows what they are doing to help you.   
                                   </li>
                                   <li>
                                        Once a battery has been removed there also the problem of how to <b>dispose of the battery</b>. You can’t just throw these things in the bin! 
                                   </li>
                               </ul>
                               <p>These are just a few of the things you must bear in mind when changing a car battery, if you have any concerns it is best to consult a professional. We can change your battery very quickly and at affordable prices, so why not give us a call.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4 className="contentTitle">What Is An Exhaust?</h4> 
                            <div className="tyresContent__partThree">
                               <p>The primary function of an exhaust is to remove the ‘exhausted’ fumes and gas from the engine or your vehicle. That sounds simple enough, but in reality modern exhausts aren’t just a pipe sticking out of the back of your car. Exhaust systems also serve many other functions in your car:</p>
                               <br/>
                               <ul>
                                   <li>
                                        <b>Reduce noise</b> – If you’ve ever had a hole in your exhaust system you’ll have noticed the noise straight away. The silencer is designed to reduce the noise of the gases as they leave the engine.
                                   </li>
                                   <li>
                                        <b>Reduce harmful emissions</b> – A catalytic converter must be fitted to all exhaust systems. A catalyst is used to convert polluting gases into less harmful ones.
                                   </li>
                                   <li>
                                        <b>Improve performance</b> – Modern exhausts now contains sensors called lambda sensors. These continually monitor your emissions and feed information back to the engine management system. This means your engine is always using the correct amount of fuel.
                                   </li>
                               </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" md="4" sm="12">
                            <ContentCard
                                header="Car, Van &amp; SUV"
                                text="Facilitating everything and every vehicle from a simple battery change to complete MOTs, exhaust replacements &amp; more. Check out Ripon for our commercial &amp; Industrial."
                            >

                            </ContentCard>
                        </Col>
                        <Col lg="4" md="4" sm="12">
                            <ContentCard
                                header="Wheel Alignment"
                                text="You're safety is important to us, we have in house state of the art laser alignment equipment to give you complete confidence and ressurance in your vehicles handling."
                            >
                                
                            </ContentCard>
                        </Col>
                        <Col lg="4" md="4" sm="12">
                            <ContentCard
                                header="Same Day Availability"
                                text="If we don't have the right size tyre in stock we can get same day delivery to both Knaresborough &amp; Ripon for same day fitting (Subject to time of day &amp; external stock)"
                            >
                                
                            </ContentCard>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col>
                            <img src={Brands} className="brandsImage" alt="Tyre Brands We Supply" />
                        </Col>
                    </Row>*/}
                    <Row>
                        <Col>
                            <PartnersSlider />
                        </Col>
                    </Row>
                </Container>
            <Footer />
            <Lightbox
                showState={lightboxIsOpen}
                closeLightbox={() => setLightboxIsOpen(false)}
            >
                <div style={{ height: '100%', width: '100%' }}>
                    <iframe
                        title="Address"
                        width="100%"
                        height="100%"
                        style={{ 'border': 0 }}
                        loading="lazy"
                        allowfullscreen
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD4AS4Rve3NyVk-YbQn8AmrKufOBP04RSw
                            &q=Knaresborough+Tyre+Services+Ltd">
                    </iframe>
                </div>
            </Lightbox>
        </>
    );
}

export default App;
