import React from 'react';
import './lightbox.scss';

function App(props) {
    return (
        <>
            {props.showState &&
                <>
                    <div className="lightboxBackground" onClick={props.closeLightbox}></div>
                    <div className="lightboxContainer">
                        {props.children}
                    </div>
                </>
            }
        </>
    );
}

export default App;