import React from 'react';
import './partners.scss';

// Slider 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Footer Carousel
import Hancook from './../../Img/company-carousel/hancook.png';
import Michelin from './../../Img/company-carousel/michelin.png';
import Davanti from './../../Img/company-carousel/davanti.png';
import Bridgestone from './../../Img/company-carousel/bridgestone.png';
import Goodyear from './../../Img/company-carousel/goodyear.png';
import Pirelli from './../../Img/company-carousel/pirelli.png';
import Continental from './../../Img/company-carousel/continental.png';
import Yokohama from './../../Img/company-carousel/yokohama.png';
import Bosch from './../../Img/company-carousel/bosch.png';
import Kumho from './../../Img/company-carousel/kumho.png';

// Lazy Load
import LazyLoad from 'react-lazyload';

function App(props) {

    var settings = {};

    if (window.innerWidth < 700) {
        settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1
        }
    } else {
        settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 1
        }
    }

    return (
        <LazyLoad once>
            <Slider {...settings} style={{marginTop: '0px', marginBottom: '50px'}}>
                <div>
                    <img src={Hancook} alt="Michelin Logo" width="100%" />
                </div>
                <div>
                    <img src={Michelin} alt="Michelin Logo" width="100%" />
                </div>
                <div>
                    <img src={Davanti} alt="Davanti Logo" width="100%" />
                </div>
                <div>
                    <img src={Bridgestone} alt="Bridgestone Logo" width="100%" />
                </div>
                <div>
                    <img src={Goodyear} alt="Goodyear Logo" width="100%" />
                </div>
                <div>
                    <img src={Pirelli} alt="Pirelli Logo" width="100%" />
                </div>
                <div>
                    <img src={Continental} alt="Continental Logo" width="100%" />
                </div>
                <div>
                    <img src={Yokohama} alt="Yokohama Logo" width="100%" />
                </div>
                <div>
                    <img src={Bosch} alt="Bosch Logo" width="100%" />
                </div>
                <div>
                    <img src={Kumho} alt="Kumho Logo" width="100%" />
                </div>
            </Slider>
        </LazyLoad>
    );
}

export default App;