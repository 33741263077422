import React from 'react';
import './footer.scss';

function App() {
    return (
        <>
            <footer>
                <p>&copy; {new Date().getFullYear()} All Rights Reserved - Knaresborough Tyre Service || Business No. 04619861 || Website By: <a href="https://www.instagram.com/im.adamgrayson/">Adam Grayson</a></p>
            </footer>
        </>
    );
}

export default App;