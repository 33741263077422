/**
 * ReactJS, SCSS
 * Ripon Page
 * Adam. G
 */

import {React, useState, useEffect} from 'react';
import './index.scss';

/**
 * Custom Components
 */
import CustomNav from '../../Components/Navbar/nav';
import Footer from '../../Components/Footer/footer';
//Lightbox
import Lightbox from '../../Components/Lightbox/lightbox';
// Partners slider
import PartnersSlider from './../../Components/SalesPartners/partners';
// Carousel
import Carousel from './../../Components/Carousel/carousel.js';

/**
 * Imports
 */
// Bootstrap
import { Container, Row, Col, InputGroup, FormControl, Button, Badge } from 'react-bootstrap';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';
// Lazy Load
import LazyLoad from 'react-lazyload';

function App() {

    useEffect(() => {
        document.title = 'Knaresborough Tyres Ripon || Commercial & Agricultural Specialists, Also Supplying Idustrial & Solid Tyres';
    }, []);

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
    const [postCode, setPostCode] = useState('');

    function Directions() {
        window.open(`https://www.google.com/maps?saddr=${postCode}&daddr=Knaresborough+Tyres+Ltd+Unit+B`); 
    }

    return (
        <>
            <CustomNav
                activePage={"ripon"}
                openFindUsLightbox={() => setLightboxIsOpen(true)}
            />
                <Container className="introImages">
                    <Carousel />
                </Container>
                <Container className="noMarginContainer">
                    <Row>
                        <Col>
                            <h1 className="welcomeText">Welcome To Knaresborough Tyres <Badge variant="secondary">Ripon Branch</Badge></h1>
                        </Col>
                    </Row>
                </Container>
                <Container className="contentContainer">
                    <Row>
                        <Col>
                            <div className="riponContent__partOne">
                                At our new centre in Ripon we offer the following services: All makes of car, van 4x4 tyres available from stock, or ordered in for same day availability. We also offer truck/commercial, agricultural, industrial & solid tyre sales & fitting with a full onsite fitting service.
                                <br/>
                                <br/>
                                We now offer full on-site fitting services for all your Commercial Tyre requirements. We understand the importance of keeping your business running smoothly. The daily wear and tear that commercial vehicles go through takes it's toll. At Knaresborough Tyre Services we have the expertise and mobile equipment to help you get back on the road quickly.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="riponContent__partTwo">
                                <ul>
                                    <li>
                                        We supply and fit a full range of agricultural tyres and provide a full on-site service in Knaresborough, Harrogate, Wetherby, Boroughbridge and Ripon areas.
                                    </li>
                                    <li>
                                        We can supply to all fleet customers.
                                    </li>
                                    <li>
                                        We offer commercial wheel balancing service.
                                    </li>
                                    <li>
                                        We also offer water ballasting service for agricultural customers.
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="12" sm="12" className="d-none d-lg-block d-md-block">
                            <div className="findUs">
                                <div className="verticalFindUsAlign">
                                    <p className="directionsText">Directions To Our Knaresborough Location Using Google:</p>
                                    <InputGroup className="mb-3">
                                        {/*<InputGroup.Prepend>
                                            <InputGroup.Text id="basic-addon1">PostCode</InputGroup.Text>
                                        </InputGroup.Prepend>*/}
                                        <FormControl
                                            placeholder="PostCode..."
                                            aria-label="PostCode..."
                                            aria-describedby="basic-addon1"
                                            value={postCode}
                                            onChange={(e) => setPostCode(e.target.value)}
                                        />
                                        <InputGroup.Append>
                                            <Button variant="secondary" onClick={() => Directions()}>Open In Google</Button>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </div>
                            </div>
                        </Col>
                        <Col lg="6" md="12" sm="12">
                            <LazyLoad once>
                                <iframe
                                    title="Address"
                                    width="100%"
                                    height="250"
                                    style={{ 'border': 0 }}
                                    loading="lazy"
                                    allowfullscreen
                                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD4AS4Rve3NyVk-YbQn8AmrKufOBP04RSw
                                        &q=Knaresborough+Tyres+Ltd+Unit+B">
                                </iframe>
                            </LazyLoad>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="enviromental">
                                <FontAwesomeIcon icon={faLeaf} className="fa-flip-horizontal" /> <i>All waste products are disposed of in accordance with environmental guidelines.</i>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <PartnersSlider />
                        </Col>
                    </Row>
                </Container>
            <Footer />
            <Lightbox
                showState={lightboxIsOpen}
                closeLightbox={() => setLightboxIsOpen(false)}
            >
                <div style={{ height: '100%', width: '100%' }}>
                    <iframe
                        title="Address"
                        width="100%"
                        height="100%"
                        style={{ 'border': 0 }}
                        loading="lazy"
                        allowfullscreen
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD4AS4Rve3NyVk-YbQn8AmrKufOBP04RSw
                            &q=Knaresborough+Tyres+Ltd+Unit+B">
                    </iframe>
                </div>
            </Lightbox>
        </>
    );
}

export default App;
