import React from 'react';
import './carousel.scss';

/**
 * Imports
 */
// Bootstrap
import { Carousel } from 'react-bootstrap'
// Webp Image Handler
import Image from 'react-image-webp';
// Lazy Load
import LazyLoad from 'react-lazyload';

/**
 * Images
 */
 import Slide1__jpg from './../../Img/slides/Slide1-min.jpg';
 import Slide1__webp from './../../Img/slides/Slide1.webp';
 import Slide2__jpg from './../../Img/slides/Slide2-min.jpg';
 import Slide2__webp from './../../Img/slides/Slide1.webp';
 import Slide3__jpg from './../../Img/slides/Slide3-min.jpg';
 import Slide3__webp from './../../Img/slides/Slide1.webp';

function App() {
    return (
        <LazyLoad once>
            <Carousel>
                <Carousel.Item interval={6000}>
                        <Image
                            className="d-block w-100"
                            src={Slide1__jpg}
                            webp={Slide1__webp}
                        />
                    <Carousel.Caption>
                        <h3>Book Today, Fit Today</h3>
                        <p>With a huge range of tyres in stock at any one time and same day availability on most, you can book today and be fitted today.</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={6000}>
                    <Image
                        className="d-block w-100"
                        src={Slide2__jpg}
                        webp={Slide2__webp}
                    />
                    <Carousel.Caption>
                        <h3>Ripon Branch</h3>
                        <p>Our Ripon facility covers all local Commercial, Agricultural, Industrial & Solid Tyres with onsite service &amp; breakdown facility</p>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={6000}>
                    <Image
                        className="d-block w-100"
                        src={Slide3__jpg}
                        webp={Slide3__webp}
                    />
                    <Carousel.Caption>
                        <h3>Family Run Business</h3>
                        <p>We're a family run business from day one operatng since 1967, that's over 50 years of trust, reliability and honesty! </p>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </LazyLoad>
    );
}

export default App;