import React, { useEffect, useState } from "react";
// Main
import { initializeApp } from "firebase/app";
// Analytics
import { getAnalytics } from "firebase/analytics";
// Data Related
import { getFirestore, collection, doc, getDocs, addDoc, deleteDoc } from "firebase/firestore/lite";
// Auth Related
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";

import { Table, Button, Form, Container, Col, Row } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css"
import "./cms.scss"

import Logo from "./../../../Img/Logo.png"
import { toast } from "react-toastify";

function ContentManagement() {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [user, setUser] = useState(null);
    // Any errors signing in?
    const [error, setError] = useState(null);
    // Fetched data
    const [data, setData] = useState([]);
    // New data for adding
    const [newDataContent, setNewDataContent] = useState();
    const [newDataColour, setNewDataColour] = useState("#2196f3");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        checkSignedInState();
        getInformationService();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            checkSignedInState();
        }, [30000]);

        return () => {
            clearInterval(interval);
        }
    }, []);

    // TODO: Add SDKs for Firebase products that you want to use
    // https://firebase.google.com/docs/web/setup#available-libraries

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional

    const firebaseConfig = {
    apiKey: "AIzaSyA38UAlMF1AVXaqOoTL9G1Q3uof8SEe8xE",
    authDomain: "kts-api-81a61.firebaseapp.com",
    projectId: "kts-api-81a61",
    storageBucket: "kts-api-81a61.appspot.com",
    messagingSenderId: "409005266119",
    appId: "1:409005266119:web:bcd10e1c3822d8b9c65ae2",
    measurementId: "G-21Q6VTSL7Y"
    };


    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    // DB Con
    const db = getFirestore(app);
    // Analytics
    const analytics = getAnalytics(app);

    // Get some data
    async function getInformationService() {
        const dataArray = [];
        const querySnapshot = await getDocs(collection(db, "informationService"));
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let compoundData = doc.data();
            compoundData.id = doc.id
            dataArray.push(compoundData);
        });

        setData(dataArray);
    }

    /**
     * Async function to add new record
     */
    async function addNew(e) {
        e.preventDefault();
        
        // Build object.
        const docData = {
            content: newDataContent,
            colour: newDataColour,
            expiry: ""
        };

        await addDoc(collection(db, "informationService"), docData); 
        getInformationService();
        toast.success("Successfully added a new record.");

        setNewDataColour("#2196f3");
        setNewDataContent("");
    }

    /**
     * Remove selected message.
     * @param {*} record 
     */
    async function deleteOld(record) {
        await deleteDoc(doc(db, "informationService", record));
        toast.success("Successfully deleted old record.");
        getInformationService();
    }
      
    /**
     * Signs the user in
     * @param {*} e 
     */
    function signUserIn(e) {
        e.preventDefault();

        const auth = getAuth();

        signInWithEmailAndPassword(auth, username, password)
        .then((userCredential) => {
            toast.info(`Signed in using ${userCredential.user.email}`);
            checkSignedInState();
        })
        .catch((error) => {
            toast.error("There are no accounts mathing those details, please try again.");
        });
    }

    /**
     * Handles user sign out procedure.
     */
     function signUserOut() {
        const auth = getAuth();
        signOut(auth).then(() => {
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
        });
    }

    /**
     * Periodically run to check user auth state.
     */
    function checkSignedInState() {

        const auth = getAuth();

        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                const uid = user.uid;
                //console.log("User is authenticated");
                setUser(uid);
            } else {
                //console.log("user is not authenticated");
                setUser(null);
            }
        });
    }

    /**
     * Function to render items
     * @returns
     */
    function renderInfoItems() {
        console.log(data);
        if (data.length >= 1) return data.map((d) => {
            return (
                <tr key={d.id}>
                    <td style={{backgroundColor: d.colour, color: "#fff"}}>{d.content}</td>
                    <td><Button variant="primary" onClick={() => deleteOld(d.id)}>Click To Remove</Button></td>
                </tr>
            );
        });

        return(
            <tr>
                <p>No data To Show</p>
            </tr>
        );
    }

    return (
        <>
            {user === null &&
                <div className="Auth-form-container">
                    <form className="Auth-form" onSubmit={signUserIn}>
                        <div className="Auth-form-content">
                            <img src={Logo} className="signInLogo" alt="KTS Logo" />
                            {error !== null &&
                                <p className="basicError">The details provided did not match any on record, please try again.</p>
                            }
                            <div className="form-group mt-3">
                                <label>Email Address</label>
                                <input className="form-control mt-1" required aria-required type="email" placeholder="Email Address" value={username} onChange={(e) => setUsername(e.target.value)} />
                            </div>
                            <div className="form-group mt-3">
                                <label>Password</label>
                                <input className="form-control mt-1" required aria-required type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button type="submit" className="btn btn-primary">
                                Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            }
            {user !== null &&
                <Container className="contentContainer">
                    <Row>
                        <Col>
                            <img src={Logo} className="signInLogo" alt="KTS Logo" />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={addNew}>
                                {success &&
                                    <p className="basicSuccess">New messages was added and is live on kts.limited webiste.</p>
                                }
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Content</Form.Label>
                                    <Form.Control as="textarea" required rows={3} value={newDataContent} onChange={(e) => setNewDataContent(e.target.value)} />
                                </Form.Group>
                                <Form.Label htmlFor="exampleColorInput">Background Colour</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={newDataColour}
                                    onChange={(e) => setNewDataColour(e.target.value)}
                                    required
                                >
                                    <option>Select a colour</option>
                                    <option value="#4caf50">Grass Green</option>
                                    <option value="#ff9800">Mustard Orange</option>
                                    <option value="#ff5722">Pastel Red</option>
                                    <option value="#607d8b">Generic Grey</option>
                                    <option value="#2196f3">Info Blue</option>
                                </Form.Control>
                                <Button type="submit" style={{marginTop: "20px",marginBottom: "20px"}} variant="primary">Add new message</Button>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table striped bordered hover>
                                <tr>
                                    <th>Content &amp; Colour</th>
                                    <th>Remove</th>
                                </tr>
                                {renderInfoItems()}
                            </Table>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button variant="secondary" onClick={signUserOut}>Logout</Button>
                        </Col>
                    </Row>
                </Container>
                
            }
        </>
    );
};

export default ContentManagement;
