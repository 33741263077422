import React from 'react';
import './card.scss';

// Bootstrap
import { Card } from 'react-bootstrap';

function App(props) {
    return (
        <Card>
            <Card.Header as="h5">{props.header}</Card.Header>
            <Card.Body>
                <Card.Text>
                    {props.text}
                </Card.Text>
                {props.children}
            </Card.Body>
        </Card>
    );
}

export default App;