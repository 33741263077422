import { React } from 'react';
import './time.scss';

import * as moment from 'moment'

function App() {

    /**
     * Function to check whether the business is open or not
     * @param {Opening Hour} openAt 
     * @param {Closing Hour} closeAt 
     */
    function checkOpenOrNot(openAt, closeAt) {

        // Curent values
        const data = todayIs();
        const time = moment();

        // Open & CLose values
        var startTime = moment(`${data.open}`, "H:mm");
        var closingTime = moment(`${data.close}`, "H:mm");

        // Check if the time is between the open/close hours
        let value = time.isBetween(startTime, closingTime);

        // The string that returns
        let returnString = '';

        // See if the current time is within the hour of close
        let hourBeforeClose = moment(closingTime).subtract(1, 'hours');
        // See if the current time is within the hour of open
        let hourBeforeOpen = moment(startTime).subtract(1, 'hours');

        /**
         * Check if the time is within an hour of closing (show Closing Soon)
         * Check if the time is within an hour of opening (show opening soon)
         * If neither of those, show closed, or open accordingly
         */
        if (value === true) {
            if (time.isAfter(hourBeforeClose)) {
                returnString = <><span style={{color: '#DE8E38', fontWeight: '700'}}>CLOSING SOON</span> - {moment(closingTime).format("H:mm")}</>;
            } else {
                returnString = <><span style={{color: 'green', fontWeight: '700'}}>OPEN</span> - Until: {moment(closingTime).format("H:mm")}</>;
            }

        } else {
            if (!data.date === 'Sunday') {
                if (time.isAfter(hourBeforeOpen)) {
                    returnString = <><span style={{color: '#DE8E38', fontWeight: '700'}}>OPENING SOON - {moment(closingTime).format("H:mm")}</span></>;
                } else {
                    returnString = <span style={{color: '#DE3838', fontWeight: '700'}}>CLOSED</span>;
                }
            } else {
                returnString = <span style={{color: '#DE3838', fontWeight: '700'}}>CLOSED</span>;
            }
        }

        return returnString
    }

    /**
     * Check's the date and what time we open/close
     */
    function todayIs() {

        const date = moment().format('dddd');

        let object = {
            date: '',
            open: '',
            close: ''
        }

        switch (date) {
            case 'Monday':
                object = {
                    date: 'Monday',
                    open: '2:00',
                    close: '17:30'
                }
                break;
            case 'Tuesday':
                object = {
                    date: 'Tuesday',
                    open: '8:15',
                    close: '17:30'
                }
                break;
            case 'Wednesday':
                object = {
                    date: 'Wednesday',
                    open: '8:15',
                    close: '17:30'
                }
                break;
            case 'Thursday':
                object = {
                    date: 'Thursday',
                    open: '8:15',
                    close: '17:30'
                }
                break;
            case 'Friday':
                object = {
                    date: 'Friday',
                    open: '8:15',
                    close: '17:30'
                }
                break;
            case 'Saturday':
                object = {
                    date: 'Saturday',
                    open: '8:15',
                    close: '12:30'
                }
                break;
            case 'Sunday':
                object = {
                    date: 'Sunday',
                    open: '00:00',
                    close: '00:00'
                }
                break;
            default:
                break;
        }

        return object
    }
    

    return (
        <p className="status">We're Currently: {checkOpenOrNot()}</p>
    );
}

export default App;
