import React, { useEffect, useState } from 'react';
import './nav.scss';

// Firebase
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore/lite";
// Analytics
import { getAnalytics, logEvent } from "firebase/analytics";

// React Router
import { Link } from "react-router-dom";

// Bootstrap
import { Container, Row, Col, Navbar, Nav, Badge } from 'react-bootstrap';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMapPin, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

// Hours open component
import Hours from './../../Components/OpenOrClosed/time.js'

// Images
import LogoOrange from './../../Img/Logo.png';
import LogoBlue from './../../Img/LogoBlue.png'
import Davanti from './../../Img/Davanti.png';
import Award from './../../Img/Award--Dark.png';
import Delivery from '../../Img/Delivery--Dark.png';
import Machine from './../../Img/Machine--Dark.png';
import Rating from './../../Img/Rating--Dark.png';

function App(props) {

    const [information, setInformation] = useState([]);

    // Firebase config
    const firebaseConfig = {
        apiKey: "AIzaSyA38UAlMF1AVXaqOoTL9G1Q3uof8SEe8xE",
        authDomain: "kts-api-81a61.firebaseapp.com",
        projectId: "kts-api-81a61",
        storageBucket: "kts-api-81a61.appspot.com",
        messagingSenderId: "409005266119",
        appId: "1:409005266119:web:bcd10e1c3822d8b9c65ae2",
        measurementId: "G-21Q6VTSL7Y"
    };
    // Initialize Firebase
    const app = initializeApp(firebaseConfig);

    useEffect(() => {
        // DB Con
        const db = getFirestore(app);

        getInformationService();
        async function getInformationService() {
            const querySnapshot = await getDocs(collection(db, "informationService"));

            // State deep clone
            let newInfoClone = [...information];

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                newInfoClone.push(doc.data());
            });
            
            setInformation(newInfoClone);
            console.log(newInfoClone);
        }
    }, []);

    var currentPage = props.activePage;
    var buttonText = '';
    var buttonLink = '';
	var contactNumber = '';

	/**
	 * 
	 */
	function contactDetails() {
		// What details are we showing? Different for Ripon & K'borough

		var returnValues = null;

		if (currentPage === 'ripon') {
			buttonText = 'Back To Knaresborough';
			buttonLink = '/';
			contactNumber = ' +44 (0) 1765 601161 ';

			returnValues = [contactNumber, <Badge className="customBadge" variant="info">Ripon Number</Badge>]
		} else {
			buttonText = 'Looking For Ripon?';
			buttonLink = '/ripon';
			contactNumber = ' +44 (0) 1423 863653 ';

			returnValues = contactNumber
		}

		return returnValues;
	}

    function getInformation() {
        return information.map((d) => {
            return (
                <div className="infoService" style={{backgroundColor: d.colour}}>
                    <p><FontAwesomeIcon icon={faInfoCircle} className="fa-flip-horizontal" style={{marginRight: "10px"}} /> {d.content}</p>
                </div>
            )
        });
    }

    function eventLogger(event, object) {
        // Analytics
        const analytics = getAnalytics(app);
        logEvent(analytics, event, object);
    }

    return (
        <>
        <Container fluid>
            <Row>
                {getInformation()}
            </Row>
        </Container>
        <Container fluid className="topNavContacts">
            <Container>
                <Row>
					{currentPage === 'ripon' && // Different variations for Ripon & K'borough
						<>
                    	<Col lg="4" md="4" sm="6" xs="6"><FontAwesomeIcon icon={faPhone} className="fa-flip-horizontal" />{contactDetails()}</Col>
						<Col lg="4" md="4" className="d-none d-lg-block d-md-block">
							<Hours />
						</Col>
						<Col lg="3" md="3" sm="6" xs="6">
							<div className="davantiLogo">
								<img 
									src={Davanti}
									width="120px"
									alt="Davanti Logo"
								/>
							</div>
						</Col>
						</>
					}
					{currentPage !== 'ripon' &&
						<>
                    	<Col lg="3" md="4" sm="6" xs="6"><FontAwesomeIcon icon={faPhone} className="fa-flip-horizontal" />{contactDetails()}</Col>
						<Col lg="6" md="5" className="d-none d-lg-block d-md-block">
							<Hours />
						</Col>
						<Col lg="3" md="3" sm="6" xs="6">
							<div className="davantiLogo">
								<img 
									src={Davanti}
									width="110px"
									alt="Davanti Logo"
								/>
							</div>
						</Col>
						</>
					}
                </Row>
            </Container>
        </Container>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="#home">
                    <Link to="/" className="nav-link">
                        <img
                            src={LogoBlue}
                            width="220"
                            height="55"
                            className="d-inline-block align-top ktsLogo"
                            alt="Knaresborough Tyres Logo"
                        />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
						{currentPage !== 'ripon' && //Don't show these links when vieweing Ripon as they don't apply
							<>
							{/*<NavDropdown title="Tyres" id="collasible-nav-dropdown">
								<Link to="/car" className="dropdown-item">Car</Link>
								<Link to="/suv" className="dropdown-item">SUV</Link>
								<Link to="/van" className="dropdown-item">Van</Link>
								<NavDropdown.Divider />
								<Link to="/commercial" className="dropdown-item">Commercial</Link>
								<Link to="/agricultural" className="dropdown-item">Agricultural</Link>
								<Link to="/industrial" className="dropdown-item">Industrial</Link>
								<NavDropdown.Divider />
								<NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
							</NavDropdown>*/}
                            <Link to="/tyres" className="nav-link">Tyres</Link>
							<Link to="/servicing" className="nav-link">MOTs &amp; Servicing</Link>
							<Link to="/batteries" className="nav-link">Batteries &amp; Exhausts</Link>
							</>
						}
                        <Link to="#" onClick={props.openFindUsLightbox} className="nav-link"><FontAwesomeIcon icon={faMapPin} className="fa-flip-horizontal" /> Find Us</Link>
                    </Nav>
                    <Nav>
                        <Link to={buttonLink} className="riponButton nav-link" onClick={(e) => eventLogger(e, "riponButton", { name: "Ripon Button" })}>{buttonText}</Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <Container fluid className="secondaryNavContacts">
            <Container style={{padding: '10px'}}>
                <Row>
                    <Col lg="3" md="4" className="d-none d-lg-block d-md-block">
                        <div className="bannerContent">
                            <img src={Award} height="45px" alt="50 Years Of Experience" />
                            <div className="innerBanner">
                                Over 50 Years Of Experience <br />
                                <span style={{fontSize: '14px', fontWeight: '400'}}>We've been a family run business since we started in 1969!</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3" md="4" className="d-none d-lg-block d-md-block">
                        <div className="bannerContent">
                            <img src={Delivery} height="40px" alt="Same Day Availability" />
                            <div className="innerBanner">
                                Over 2000 Tyres In Stock<br />
                                <span style={{fontSize: '14px', fontWeight: '400'}}>Or we offer same day availability if we don't have them in stock!</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3" md="4" className="d-none d-lg-block d-md-block">
                        <div className="bannerContent">
                            <img src={Machine} height="40px" alt="State Of The Art Equipment" />
                            <div className="innerBanner">
                                State Of The Art Equipment <br />
                                <span style={{fontSize: '14px', fontWeight: '400'}}>We're constantly re-investing in new high tech equipment.</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3" className="d-none d-lg-block">
                        <div className="bannerContent">
                            <img src={Rating} height="45px" alt="Trustworthy & Reliable" />
                            <div className="innerBanner">
                                You Can Trust Us! <br />
                                <span style={{fontSize: '14px', fontWeight: '400'}}>Check out our 4.9 star Rating on Google from over 180 customers!</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
        </>
    );
}

export default App;