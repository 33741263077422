import React from 'react';
import './reviews.scss';

// Slider 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

// Images
import Google from './../../Img/google.png';

function App(props) {

    var settings = {};

    if (window.innerWidth < 800) {
        if (window.innerWidth < 500) {
            settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        } else {
            settings = {
                dots: true,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        }
    } else {
        settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 5,
            slidesToScroll: 1
        }
    }

    return (
        <Slider className="reviewsSlider" {...settings} style={{marginTop: '50px', marginBottom: '50px'}}>
            <div>
                <div className="verticalAlign">
                    <img src={Google} width="100%"/>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Asha <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>Thanks so much! Had my car in with Alfie and he made sure it was done at a time suitable for me and was done within 30 mins! Would recommend to everyone.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Rachel <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>Fab service, I turned up unannounced with a nail in my tyre.. two new tires in half an hr! My hero's!</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Linda <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>Thank you so much for your brilliant service this morning.  I arrived very frazzled after having a puncture and being driven into at a junction and everyone was so helpful!  Thoroughly recommend you.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Alan <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>A well established yet newly relocated (from opposite the station) specialist tyre service in Knaresborough. Fast, friendly, well priced service. Have found their prices very keen! What's not to like?</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Martin <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>Great service everytime , i have to deal with them most days and i still take my car there cos its done right by professionals</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Brent <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>Can't say enough how much I appreciate their flexibility in getting our vehicles in when we get a flat tyre. With a busy family, being able to drop in short notice with an emergency is very reassuring.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Isobel <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>These guys are brilliant. So fair and really pleasant to deal with. Henry is great! Wish I knew about them years ago.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Andrew <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>Never in over forty years has any other tyre service in the area beaten this place for tyre price and quality in both product and service, don't even try to beat the price just get your tyres changed here</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Catrina <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>Great advice, value and service on specialist 4x4 tyres for a Mercedes. Highly recommended. Many thanks in particular to Craig and Henry!</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="verticalAlign">
                    <div className="verticalContent">
                        <h4>Gilly <FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /><FontAwesomeIcon icon={faStar} className="fa-flip-horizontal" /></h4>
                        <p>Really fast and friendly. They put on my winter tyres. I even saw a customer being told that they didn't need new tyres yet, having checked them. Nice to know</p>
                    </div>
                </div>
            </div>
        </Slider>
    );
}

export default App;