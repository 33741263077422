import React from "react";
import { Container, Col, Row, Form, InputGroup, Button } from "react-bootstrap";

import "./alternate.scss";
import SlideOneRight from "./../../Img/New Content/title-2.png";

function Alternate() {
    return (
        <Container fluid className="alternateWelcomeBG" style={{backgroundImage: `url(${SlideOneRight})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", backgroundPosition: "center"}}>
            <Container>
                <Col>
                    <Row>
                        <div className="contentContainer">
                            <h1 id="alternateWelcomeH1">Competetively priced family run business</h1>
                            <p>Most competetive prices on Davanti budget tyres, guaranteed! Over 2000 Tyres In Stock, or we offer same day availability if we don't have them in stock!</p>

                            <div className="recruitmentBanner">
                                We're recruiting! Looking for an exciting career in the motor industry, call us to find out more!
                                <a href="tel:01423863653">+44 1423 863653</a>
                            </div>
                        </div>
                    </Row>
                </Col>
            </Container>
        </Container>
    );
}

export default Alternate;