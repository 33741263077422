/**
 * ReactJS, SCSS
 * Servicing Page
 * Adam. G
 */

import {React, useState, useEffect} from 'react';
import './index.scss';

/**
 * Custom Components
 */
import CustomNav from '../../Components/Navbar/nav';
import Footer from '../../Components/Footer/footer';
//Lightbox
import Lightbox from '../../Components/Lightbox/lightbox';
// Content Card
import ContentCard from '../../Components/Card/card';
// Partners slider
import PartnersSlider from '../../Components/SalesPartners/partners';

/**
 * Imports
 */
// Bootstrap
import { Container, Row, Col } from 'react-bootstrap';
// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf } from '@fortawesome/free-solid-svg-icons';


function App() {

    useEffect(() => {
        document.title = 'MOTs & Servicing - State Of The Art Equipment || Knaresborough Tyres';
    }, []);

    const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

    return (
        <>
            <CustomNav
                activePage={"home"}
                openFindUsLightbox={() => setLightboxIsOpen(true)}
            />
                <Container className="contentContainer">
                    <Row>
                        <Col lg="6" md="12">
                            <div className="servicingContent__partOne">
                                We are pleased to offer servicing & MOTs at our state of the art facility, we cater for most models of cars, 4x4, SUV & vans. We are able to provide a comprehensive servicing and repair packages for your vehicle. All oils and filters we use meet manufacturers specifications.
                            </div>
                        </Col>
                        <Col lg="6" md="12">
                            <div className="servicingContent__partTwo">
                                Other available services include batteries &amp; exhausts checked & both replaced as necessary. All our new batteries carry a 3 year warranty. We offer a full four-wheel fully digital alignment service, steering &amp; suspension checks. Our workshop provides full diagnostics checks by our technicians.
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="enviromental">
                                <FontAwesomeIcon icon={faLeaf} className="fa-flip-horizontal" /> <i>All waste products are disposed of in accordance with environmental guidelines.</i>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4 className="contentTitle">Why Should I Service My Car?</h4> 
                            <div className="tyresContent__partThree">
                               <p>Buying a car is an expensive endeavour. Once you’ve shelled out for a new car it is easy to forget about servicing or to put it off to try and save money. But regular servicing is very important for the health of your car and will save you money in the long run. MOTs cover the main safety inspections that your car needs, but servicing your car regularly will help prolong the life of your engine. We offer affordable, comprehensive services for all types of cars, vans and 4 x 4s.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="4" md="4" sm="12">
                            <ContentCard
                                header="Car, Van &amp; SUV"
                                text="Facilitating everything and every vehicle from a simple battery change to complete MOTs, exhaust replacements &amp; more. Check out Ripon for our commercial &amp; Industrial."
                            >

                            </ContentCard>
                        </Col>
                        <Col lg="4" md="4" sm="12">
                            <ContentCard
                                header="Wheel Alignment"
                                text="You're safety is important to us, we have in house state of the art laser alignment equipment to give you complete confidence and ressurance in your vehicles handling."
                            >
                                
                            </ContentCard>
                        </Col>
                        <Col lg="4" md="4" sm="12">
                            <ContentCard
                                header="Same Day Availability"
                                text="If we don't have the right size tyre in stock we can get same day delivery to both Knaresborough &amp; Ripon for same day fitting (Subject to time of day &amp; external stock)"
                            >
                                
                            </ContentCard>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col>
                            <img src={Brands} className="brandsImage" alt="Tyre Brands We Supply" />
                        </Col>
                    </Row>*/}
                    <Row>
                        <Col>
                            <PartnersSlider />
                        </Col>
                    </Row>
                </Container>
            <Footer />
            <Lightbox
                showState={lightboxIsOpen}
                closeLightbox={() => setLightboxIsOpen(false)}
            >
                <div style={{ height: '100%', width: '100%' }}>
                    <iframe
                        title="Address"
                        width="100%"
                        height="100%"
                        style={{ 'border': 0 }}
                        loading="lazy"
                        allowfullscreen
                        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD4AS4Rve3NyVk-YbQn8AmrKufOBP04RSw
                            &q=Knaresborough+Tyre+Services+Ltd">
                    </iframe>
                </div>
            </Lightbox>
        </>
    );
}

export default App;
